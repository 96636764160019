<template>
  <div class="">
    <div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <button
            class="
              bg-white
              w-full
              py-3
              px-4
              rounded-lg
              shadow
              border-2 border-green-400
            "
          >
            Cashpower
          </button>
        </div>
        <div class="w-full md:w-1/3 px-3">
          <button
            class="
              bg-gray-100
              w-full
              py-3
              px-4
              rounded-lg
              shadow
              cursor-not-allowed
            "
            disabled
          >
            Send Money
          </button>
        </div>

        <div class="w-full md:w-1/3 px-3">
          <button
            class="
              bg-gray-100
              w-full
              py-3
              px-4
              rounded-lg
              shadow
              cursor-not-allowed
            "
            disabled
          >
            Receive Money
          </button>
        </div>
      </div>
      <hr />
      <div class="flex flex-wrap mb-6 mt-9">
        <div
          class="w-full md:w-2/3 px-3 mb-6 md:mb-0 bg-white shadow px-4 py-7"
        >
          <div class="input-wrapper border-b pb-6">
            <label
              class="
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
              "
              for="grid-first-name"
            >
              Enter Meter Number
            </label>
            <PincodeInput v-model="cashpowerNum" length="11" placeholder="0" />
          </div>

          <div class="mt-6">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  class="
                    block
                    uppercase
                    tracking-wide
                    text-gray-700 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-first-name"
                >
                  Meter Number
                </label>
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border
                    rounded
                    py-3
                    px-4
                    mb-3
                    leading-tight
                    focus:outline-none focus:bg-white
                  "
                  id="grid-first-name"
                  type="text"
                  readonly
                />
                <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label
                  class="
                    block
                    uppercase
                    tracking-wide
                    text-gray-700 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-last-name"
                >
                  Customer's Name
                </label>
                <input
                  class="
                    appearance-none
                    block
                    w-full
                    bg-gray-200
                    text-gray-700
                    border border-gray-200
                    rounded
                    py-3
                    px-4
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-gray-500
                  "
                  id="grid-last-name"
                  type="text"
                  readonly
                />
                <p class="text-sm text-gray-500 mt-2">
                  Please Confirm With the buyer if the name above owns the
                  meter.
                </p>
              </div>
            </div>
          </div>

          <div
            class="
              flex flex-col
              justify-end
              items-end
              text-right
              mt-20
              border-b
            "
          >
            <div class="w-80 px-3 mb-6">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-first-name"
              >
                Amount
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-50
                  font-extrabold
                  text-2xl text-right text-gray-700
                  border
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                id="grid-first-name"
                type="number"
                placeholder="0.00"
              />
              <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
            </div>

            <div class="w-80 px-3 mb-6">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-first-name"
              >
                Cashpower Amount
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-50
                  font-extrabold
                  text-2xl text-right text-gray-700
                  border
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                value="GMD"
                readonly
              />
              <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
            </div>

            <div class="w-80 px-3 mb-6">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-first-name"
              >
                Transaction Fee
              </label>
              <input
                class="
                  appearance-none
                  block
                  w-full
                  bg-gray-50
                  font-extrabold
                  text-2xl text-right text-gray-700
                  border
                  rounded
                  py-3
                  px-4
                  mb-3
                  leading-tight
                  focus:outline-none focus:bg-white
                "
                type="text"
                value="GMD"
                readonly
              />
              <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
            </div>
          </div>

          <div class="flex flex-col justify-end items-end text-right mt-10">
            <div class="w-80 px-3 mb-6">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-first-name"
              >
                Total
              </label>
              <h1
                class="
                  flex flex-col
                  justify-end
                  items-end
                  text-right
                  mt-4
                  text-4xl
                "
              >
                GMD :
              </h1>

              <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
            </div>

            <div class="w-80 px-3 mb-6">
              <button
                class="
                  bg-green-400
                  text-bold
                  hover:bg-green-500
                  py-3
                  px-9
                  rounded-lg
                  text-white
                "
              >
                Continue
              </button>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 bg-white shadow">
          <div class="input-wrapper border-b py-6 flex justify-center">
            <button
              class="
                uppercase
                text-gray-700
                font-bold
                py-3
                px-4
                border border-gray-400
                rounded-lg
                hover:bg-gray-100
                text-white
              "
            >
              <i class="bx bx-printer"></i>

              Print
            </button>
          </div>

          <div class="flex justify-center mt-10">
            <recipet />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PincodeInput from "vue-pincode-input";
import Recipet from "../../components/Recipet.vue";
// import BranchSales from "../../components/Charts/BranchSales";

export default {
  layout: "default",
  components: {
    Recipet,
    PincodeInput,

    // BranchSales,
    // HelloWorld
  },

  data: () => ({
    cashpowerNum: null,
  }),
};
</script>